<template>
  <div class="navbar-container d-flex content align-items-center">

    <!-- Nav Menu Toggler -->
    <ul class="nav navbar-nav d-xl-none">
      <li class="nav-item">
        <b-link
            class="nav-link"
            @click="toggleVerticalMenuActive"
        >
          <feather-icon
              icon="MenuIcon"
              size="21"
          />
        </b-link>
      </li>
    </ul>
    <!--search bar-->
      <search-bar/>
    <!-- Left Col -->
    <div class="align-items-center mr-3">
      <dark-Toggler class="d-none d-lg-block"/>
    </div>

    <!-- Left Col -->
    <div class="align-items-center mr-3">
      <clock/>
    </div>

    <b-navbar-nav class="nav align-items-center ml-auto">
      <!-- Notification -->
      <notification-dropdown/>
      <!-- Language -->
      <b-nav-item-dropdown
          right
          toggle-class="d-flex align-items-center dropdown-user-link"
          class="dropdown-user"
      >
        <template #button-content>
          <div>
            <country-flag class="my-0 py-0" :country="currentLocale.country" size="normal"/>
          </div>
        </template>
        <b-dropdown-item link-class="d-flex align-items-center" @click="()=>setLanguage('en')">
          <country-flag class="my-0 py-0" country="gb" size="normal"/>
          <div class="text-center w-100">English</div>
        </b-dropdown-item>
        <b-dropdown-item link-class="d-flex align-items-center" @click="()=>setLanguage('fr')">
          <country-flag class="my-0 py-0" country="fr" size="normal"/>
          <div class="text-center w-100">Français</div>
        </b-dropdown-item>
      </b-nav-item-dropdown>
      <!-- User Profile -->
      <b-nav-item-dropdown
          right
          toggle-class="d-flex align-items-center dropdown-user-link"
          class="dropdown-user"
      >
        <template #button-content>
          <div class="d-sm-flex d-none user-nav">
            <p class="user-name font-weight-bolder mb-0" style="font-size: 9px">
              {{ userFullName }}
            </p>
            <span class="user-status" v-if="myRole!=null">{{$t(`roles.position.${myRole.companyPosition}.name`)}}</span>
          </div>
          <b-avatar
              size="40"
              variant="light-primary"
              badge
              :src="require(`@/assets/images/avatars/${user.avatar || '1'}-small.png`)"
              class="badge-minimal"
              badge-variant="success"
          />
        </template>

        <b-dropdown-item link-class="d-flex align-items-center">
          <feather-icon
              size="16"
              icon="UserIcon"
              class="mr-50"
          />
          <span>Profile</span>
        </b-dropdown-item>

        <b-dropdown-item link-class="d-flex align-items-center">
          <feather-icon
              size="16"
              icon="MailIcon"
              class="mr-50"
          />
          <span>Inbox</span>
        </b-dropdown-item>

        <b-dropdown-item link-class="d-flex align-items-center">
          <feather-icon
              size="16"
              icon="CheckSquareIcon"
              class="mr-50"
          />
          <span>Task</span>
        </b-dropdown-item>

        <b-dropdown-item link-class="d-flex align-items-center">
          <feather-icon
              size="16"
              icon="MessageSquareIcon"
              class="mr-50"
          />
          <span>Chat</span>
        </b-dropdown-item>

        <b-dropdown-divider/>

        <b-dropdown-item link-class="d-flex align-items-center">
          <feather-icon
              size="16"
              icon="LogOutIcon"
              class="mr-50"
          />
          <span>Logout</span>
        </b-dropdown-item>
      </b-nav-item-dropdown>
    </b-navbar-nav>
  </div>
</template>

<script>
import DarkToggler from '@core/layouts/components/app-navbar/components/DarkToggler.vue';
import { mapGetters, mapMutations } from 'vuex'
import CountryFlag from 'vue-country-flag';
import NotificationDropdown from '@core/layouts/components/app-navbar/components/NotificationDropdown';
import Clock from '@/components/widget/utils/clock';
import SearchBar from '@/components/widget/utils/searchbar';
export default {
  components: {
    NotificationDropdown,
    CountryFlag,
    DarkToggler,
    Clock,
    SearchBar
  },

  computed: {
    ...mapGetters('user', ['user', 'userFullName', 'myRole']),
    ...mapGetters('translator', ['currentLocale'])
  },
  props: {
    toggleVerticalMenuActive: {
      type: Function,
      default: () => {
      },
    },
  },
  methods:{
    ...mapMutations('translator',['setLanguage'])
  }


};
</script>


