<template>
  <div class="w-50">
    <b-input-group>
      <b-input-group-append>
        <feather-icon
            style="margin-top: 8px; margin-right: 1vh"
            icon="SearchIcon"
            size="21"
        />
      </b-input-group-append>
      <b-form-input
          rounded
          v-model="searchTerm"
          @input="debouncedSearch"
          placeholder="Global Search..."
      />
    </b-input-group>

    <div
        v-if="searchResults.length > 0 && isSearchResultsVisible"
        class="search-results"
        style="width: 49%"
    >
      <b-list-group>
        <router-link
            :to="{ name: 'sales', query: { clientId: result.id, tab: 0 } }"
            v-for="result in searchResults"
            :key="result.id"
            @click.native="handleLinkClick"
        >
          <b-list-group-item>
            <div class="d-flex align-items-center">
              <country-flag
                  :country="result.country"
                  size="normal"
                  style="margin-right: 0.1vh"
              />
              <span>
                #{{ result.id }}
                <span v-html="highlightText(result.firstName)"></span>
                {{ result.lastName }}
                (<span v-html="highlightText(result.email)"></span>)
              </span>
            </div>
          </b-list-group-item>
        </router-link>
      </b-list-group>
    </div>
  </div>
</template>

<script>
import _debounce from 'lodash/debounce';
import CountryFlag from 'vue-country-flag';
import ClientSearchRequest from '@/model/client/ClientSearchRequest';
import { mapActions } from 'vuex';

export default {
  name: 'searchbar',
  components: {
    CountryFlag,
  },

  data() {
    return {
      searchTerm: '',
      filter: ClientSearchRequest(),
      clients: [],
      searchResults: [],
      isSearchResultsVisible: false,
    };
  },

  methods: {
    ...mapActions('clients', ['getAllClientsSearch']),
    handleLinkClick() {
      this.searchTerm = '';
      this.isSearchResultsVisible = false;
    },

    closeSearchResults(event) {
      if (!this.$el.contains(event.target)) {
        this.isSearchResultsVisible = false;
        this.searchTerm = '';
      }
    },

    debouncedSearch: _debounce(function () {
      this.search();
    }, 500),

    search() {
      if (this.searchTerm.length >= 2) {
        let $this = this;
        this.filter.search = this.searchTerm;
        this.filter.pagination = {
          page: 1,
          size: 999,
        };
        this.filter.sort= {
          direction: 'DESC',
              field: 'creationTime',
        }

        this.getAllClientsSearch({
          filter: this.filter,
        })
            .then((ans) => {
              $this.clients = ans.data;
              $this.searchResults = this.clients.filter((client) =>
                  $this.containsKeyword(client.id) ||
                  $this.containsKeyword(client.firstName) ||
                  $this.containsKeyword(client.lastName) ||
                  $this.containsKeyword(client.phone) ||
                  $this.containsKeyword(client.mobile) ||
                  $this.containsKeyword(client.email)
              );
              $this.isSearchResultsVisible = true;
            });
      } else {
        this.searchResults = [];
        this.isSearchResultsVisible = false;
      }
    },

    containsKeyword(text) {
      if (text == null) return false;
      return text.toLowerCase().includes(this.searchTerm.toLowerCase());
    },

    highlightText(text) {
      const regex = new RegExp(`(${this.searchTerm})`, 'gi');
      return text.replace(regex, '<strong>$1</strong>');
    },
  },

  beforeDestroy() {
    document.body.removeEventListener('click', this.closeSearchResults);
  },
};
</script>

<style scoped>
.search-results {
  position: absolute;
  max-height: 250px;
  width: 100%;
  overflow-y: auto;
  margin-top: 5px;
}
</style>
