<template>
  <div>
    <span>{{ currentDate }} {{  currentTime}}</span>
  </div>
</template>

<script>
export default {
  name: 'clock',
  data() {
    return {
      currentDate: '',
      currentTime: ''
    };
  },
  mounted() {
    // Update date and time initially
    this.updateDateTime();

    // Update date and time every second
    setInterval(() => {
      this.updateDateTime();
    }, 1000);
  },
  methods: {
    updateDateTime() {
      const now = this.$moment();

      // Format date as DD.MM.YYYY
      const formattedDate = now.format(this.$t('timeFormat.date.format'))

      // Format time in 24-hour HH:MM:SS format
      const formattedTime = now.format(this.$t('timeFormat.time.format'));

      // Update data properties
      this.currentDate = formattedDate;
      this.currentTime = formattedTime;
    },
  }
};
</script>

<style scoped>
/* Add your CSS styles here */
</style>
