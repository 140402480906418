<template>
  <li class='navigation-header mt-2'>
    <span>{{ $t(`menu.${item.header}`) }}</span>
  </li>
</template>

<script>

export default {
  props: {
    item: {
      type: Object,
      required: true,
    },
  }
}
</script>
