<template>
  <ul>
    <component
      :is="resolveNavItemComponent(item)"
      v-for="item in filteredItems"
      :key=" item.header ? `header-${item.header}` : `title-${item.title}`"
      :item="item"
    />
    <div v-if="myRole == null" class="text-center mt-1">
      <b-spinner label="Spinning"></b-spinner>
    </div>

  </ul>
</template>

<script>
import { mapGetters } from 'vuex'
import { resolveVerticalNavMenuItemComponent as resolveNavItemComponent } from '@core/layouts/utils'
import VerticalNavMenuHeader from '../vertical-nav-menu-header/index'
import VerticalNavMenuLink from '../vertical-nav-menu-link/VerticalNavMenuLink.vue'
import VerticalNavMenuGroup from '../vertical-nav-menu-group/VerticalNavMenuGroup.vue'
import mixinRouter from '@/components/mixin/mixinRouter'

export default {
  name: 'VerticalNavMenuItems',
  mixins:[mixinRouter],
  components: {
    VerticalNavMenuHeader,
    VerticalNavMenuLink,
    VerticalNavMenuGroup,
  },
  data: () => ({
    resolveNavItemComponent,
  }),
  props: {
    items: {
      type: Array,
      required: true,
    },
  },
  computed: {
    ...mapGetters('user', ['myRole', 'isAdminUser']),
    filteredItems() {
      if(this.isAdminUser) return this.items
      let items = this.items.filter((item) => this.routerIsAccessible(item.route))
      return items.filter((item, index) => {
        if (item.header != null) {
          return items[index + 1] && items[index + 1].header == null
        }
        if (item.children) {
          item.children = item.children.filter((child) => this.routerIsAccessible(child.route))
          return item.children.length > 0
        }
        return true
      })
    },
  },
}
</script>
