<template>
  <li
      v-if="canViewVerticalNavMenuLink(item)"
      class="nav-item"
      :class="{
      'active': isActive,
      'disabled': item.disabled
    }"
  >
    <b-link
        v-bind="linkProps"
        class="d-flex align-items-center"
    >
      <!--Icon-->
      <i :class="item.icon" v-if="item.iconType === 'font-awesome'"></i>
      <feather-icon v-else :icon="item.icon || 'CircleIcon'" />
      <!--End Icon-->
      <span class="menu-title text-truncate">{{ $t(`menu.${item.title}`) }}</span>
      <b-badge
          v-if="item.tag"
          pill
          :variant="item.tagVariant || 'primary'"
          class="mr-1 ml-auto"
      >
        {{ item.tag }}
      </b-badge>
    </b-link>
  </li>
</template>

<script>
import { BBadge, BLink } from 'bootstrap-vue'
import useVerticalNavMenuLink from './useVerticalNavMenuLink'
import mixinVerticalNavMenuLink from './mixinVerticalNavMenuLink'

export default {
  components: {
    BLink,
    BBadge,
  },
  mixins: [mixinVerticalNavMenuLink],
  props: {
    item: {
      type: Object,
      required: true,
    },
  },
  setup(props) {
    const { isActive, linkProps, updateIsActive } = useVerticalNavMenuLink(props.item)
    const canViewVerticalNavMenuLink = (item) => {
      if (item.meta) {
        if (item.meta.resource) {
          return this.$can(item.meta.resource, item.meta.action)
        }
      }
      return true
    }


    return {
      isActive,
      linkProps,
      updateIsActive,

      // ACL
      canViewVerticalNavMenuLink,
    }
  },

}
</script>
