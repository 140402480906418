<template>
  <b-nav-item-dropdown ref="notification-dropdown" class="dropdown-notification mr-25" menu-class="dropdown-menu-media" right @show="onShow">
    <template #button-content>
      <feather-icon v-bind:badge="totalOfUnreaded" badge-classes="bg-danger" class="text-body" icon="BellIcon" size="21"/>
    </template>

    <!-- Header -->
    <li class="dropdown-menu-header">
      <div class="dropdown-header d-flex">
        <h4 class="notification-title mb-0 mr-auto">
          Notifications
        </h4>
        <b-badge pill variant="light-primary" v-if="totalOfUnreaded > 0">
          {{totalOfUnreaded}} New
        </b-badge>
      </div>
    </li>

    <!-- Notifications -->
    <li class="scrollable-container media-list scroll-area custom-scrollbar" @scroll="scrollHandle"  style="height: 600px; overflow: auto" >
      <!-- Account Notification -->
      <b-link v-for="notification in notifications" :key="notification.id">
        <b-media>
          <template #aside>
            <b-avatar size="32" :src="notification.avatar" :text="notification.avatar" :variant="$t(`notifications.${notification.source}.color`)"/>
          </template>
          <span class="float-right text-muted" style="font-size: 10px"> {{notification.time | moment($t('timeFormat.dateTime.format')) }}</span>

          <p class="media-heading">
            <span class="font-weight-bolder">
             {{$t(`notifications.${notification.source}.${notification.content}.title`, notification.params)}}
            </span>
          </p>
          <small class="notification-text"> {{$t(`notifications.${notification.source}.${notification.content}.body`, notification.params)}}</small>
          <br/>

        </b-media>
      </b-link>
      <div v-if="loading" class="text-center">
        <b-spinner>{{ $t('operations.loading') }}</b-spinner>
      </div>
    </li>

    <!-- Cart Footer -->
    <li class="dropdown-menu-footer"><b-button
      variant="primary"
      block
      @click="readAllNotifications"
    >{{$t('operations.readAllNotifications')}}</b-button>
    </li>
  </b-nav-item-dropdown>
</template>

<script>
import VuePerfectScrollbar from 'vue-perfect-scrollbar'

import { mapActions, mapGetters } from 'vuex'
import mixinBase from '@/components/mixin/mixinBase'
import mixinTable from '@/components/mixin/mixinTable'

export default {
  components:{VuePerfectScrollbar},
  mixins:[ mixinBase, mixinTable,],
  data:()=>({
    notifications: [],
  }),
  computed:{
    ...mapGetters('notification', ['totalOfUnreaded']),
  },
  methods:{
    ...mapActions('notification', ['getNotifications', 'setAllNotificationsAsRead']),
    onShow(){
      this.pagination.page = 1;
      this.fetchNotifications();
    },
    fetchNotifications() {
      let loadingToken = this.setLoading(true);
      this.getNotifications(this.pagination).then(response=>{
        if(response.pagination.page === 1) {
          this.notifications = response.data
        } else {
          this.notifications = this.notifications.concat(response.data)
        }
        this.setMetaDataFromResult(response);
      }).finally(()=>{
        this.setLoaded(loadingToken);
      })
    },
    scrollHandle(evt) {
      let data = {
        scrollTop: evt.srcElement.scrollTop,
        scrollHeight: evt.srcElement.scrollHeight,
        clientHeight: evt.srcElement.clientHeight,
        scrollBottom: evt.srcElement.scrollTop + evt.srcElement.clientHeight,
        isBottom: evt.srcElement.scrollTop + evt.srcElement.clientHeight >= evt.srcElement.scrollHeight
      }
      if(data.isBottom && !this.loading && this.pagination.page < this.pagination.pages) {
        this.pagination.page++;
        this.fetchNotifications();
      }
    },
    readAllNotifications(){
      this.setAllNotificationsAsRead();
      this.$refs['notification-dropdown'].hide();
    }
  },
}
</script>

<style>

</style>
