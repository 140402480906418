export default [
  {
    header: 'home'
  },
 /* {
    title: 'home',
    route: 'home',
    icon: 'HomeIcon',
  },*/
  {
    title: 'calendar',
    route: 'calendar',
    icon: 'CalendarIcon',
  },
  {
    title: 'hierarchy',
    route: 'hierarchy',
    icon: 'fa-solid fa-folder-tree',
    iconType: 'font-awesome',  },
  /* {
     header: 'reports',
     icon: 'FileIcon',
   },
   {
     title: 'reports',
     route: 'clients',
     icon: 'fa-solid fa-file',
     iconType: 'font-awesome',
   },*/
  {
    header: 'clients',
    icon: 'FileIcon',
  },
   {
    title: 'all',
    route: 'clients',
    icon: 'fa-solid fa-user-group',
    iconType: 'font-awesome',
  },
  {
    title: 'sales',
    route: 'sales',
    icon: 'fa-solid fa-user-tag',
    iconType: 'font-awesome',
  },
  {
    title: 'retention',
    route: 'retention',
    icon: 'fa-solid fa-user-shield',
    iconType: 'font-awesome',
  },
  {
    title: 'kycs',
    route: 'kycs',
    icon: 'fa-regular fa-folder-open',
    iconType: 'font-awesome',
  },
  {
    title: 'affiliates',
    route: 'affiliates',
    icon: 'fa-solid fa-building-user',
    iconType: 'font-awesome',
  },
  {
    header: 'trading',
    icon: 'FileIcon',
  },
  {
    title: 'symbols',
    route: 'symbols',
    icon: 'fa-solid fa-coins',
    iconType: 'font-awesome',
  }, {
    title: 'symbolGroups',
    route: 'symbolGroups',
    icon: 'fa-solid fa-filter-circle-dollar', //icon: 'fa-brands fa-stack-exchange',
    iconType: 'font-awesome',
  },
  {
    title: 'openTrades',
    route: 'trades',
    icon: 'fa-solid fa-exchange-alt',
    iconType: 'font-awesome',
  },
  {
    title: 'closedTrades',
    route: 'closedTrades',
    icon: 'fa-solid fa-history',
    iconType: 'font-awesome',
  },
  {
    title: 'accounts',
    route: 'accounts',
    icon: 'fa-solid fa-user-circle',
    iconType: 'font-awesome',
  },
  {
    header: 'monetaries',
  },
  {
    title: 'monetaries',
    route: 'monetaries',
    icon: 'CreditCardIcon',
  },
  {
    title: 'withdrawalRequests',
    route: 'withdrawalRequests',
    icon: 'fa-solid fa-comment-dollar',
    iconType: 'font-awesome',
  },
  {
    header: 'reports',
    icon: 'FileIcon',
  },
  {
    title: 'salesDashboard',
    route: 'salesDashboard',
    icon: 'fa-solid fa-comment-dollar',
    iconType: 'font-awesome',
  },
  {
    title: 'affiliateDashboard',
    route: 'affiliateDashboard',
    icon: 'fa-solid fa-comment-dollar',
    iconType: 'font-awesome',
  },
  {
    header: 'activity',
    icon: 'ActivityIcon',
  },
  {
    title: 'userActivity',
    route: 'userActivity',
    icon: 'ActivityIcon',
  },
/*
  {
    title: 'userDashboard',
    route: 'userDashboard',
    icon: 'fa-solid fa-comment-dollar',
    iconType: 'font-awesome',
  },*/
  {
    header: 'configurations',
    icon: 'SettingsIcon',
  },
  {
    title: 'organizations',
    route: 'organizations',
    icon: 'fa-solid fa-landmark',
    iconType: 'font-awesome',
  },
  {
    title: 'businessesUnits',
    route: 'businessUnit',
    icon: 'fa-solid fa-landmark',
    iconType: 'font-awesome',
  },
  {
    title: 'users',
    route: 'users',
    icon: 'fa-solid fa-users',
    iconType: 'font-awesome',
  },
  {
    title: 'roles',
    route: 'roles',
    icon: 'fa-solid fa-user-tag',
    iconType: 'font-awesome',
  },
  {
    title: 'smtps',
    route: 'smtps',
    icon: 'fa-solid fa-envelope',
    iconType: 'font-awesome',
  },
  {
    title: 'mailTemplates',
    route: 'mails',
    icon: 'fa-solid fa-envelope-open-text',
    iconType: 'font-awesome',
  },
  {
    title: 'clientStatuses',
    route: 'clientStatus',
    icon: 'fa-solid fa-stream',
    iconType: 'font-awesome',
  },
  {
    header: 'help',
  },
  {
    title: 'support',
    route: 'config-support',
    iconType: 'font-awesome',
    icon: 'fa-solid fa-headset',
  },
  {
    title: 'about',
    route: 'config-about',
    iconType: 'font-awesome',
    icon: 'fa-regular fa-circle-question',
  }
];
